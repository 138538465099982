
import ErrorBoundary from 'components/error-boundary/ErrorBoundary';
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import PublicLayout from 'views/common/layout/PublicLayout';
import * as routes from '../routes';
import './App.css';
import NotFound from './error/NotFound';

const App = () => {

  return (

    <div className="app">
      <ErrorBoundary>
        <Switch>
          {_.map(routes.publicRoutes, (route, key) => {

            const { component, path } = route;
            return (
              <Route
                exact
                path={path}
                key={key}
                render={(route) =>
                  <PublicLayout component={component} route={route} />
                }
              />
            )
          })}

          <Route exact path="/" render={() => (<Redirect to="/solicitud" />)} />
          <Route component={NotFound} />
        </Switch>
      </ErrorBoundary>
    </div>

  );

}

export default App;
