import { combineReducers } from 'redux';
//import { intlReducer } from 'react-intl-redux';
import intlReducer from './intl';
import requestReducer from './request';

const createRootReducer = (history) => combineReducers({
  intl: intlReducer,
  request: requestReducer
});

export default createRootReducer;
