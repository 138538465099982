import { createStore, applyMiddleware, compose } from 'redux';
import { locales } from 'i18n';
import { createBrowserHistory } from 'history';
import createRootReducer from './rootReducer';
import storageSession from 'redux-persist/lib/storage/session';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import { configureStore } from "@reduxjs/toolkit";
import thunk from 'redux-thunk';

export const history = createBrowserHistory();

const initialState = {
  intl: {
    defaultLocale: 'es',
  }
};

const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist:['intl', 'router']
};


/*export const store = createStore(
  persistReducer(persistConfig, createRootReducer(history)),
  initialState,
  compose(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware
    )
  )
); */

export const store = configureStore({
  reducer: persistReducer(persistConfig, createRootReducer(history)),
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(thunk),
  initialState,
});


export const persistor = persistStore(store);
