import React from 'react';
import styles from './GenericError.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

import { useIntl } from 'react-intl';

const GenericError = (props) => {
    const {formatMessage: f} = useIntl();

    return (
      <div className={cx('container', 'h-100', 'd-flex', 'flex-column', 'justify-content-center', 'align-items-center')}>
        <div className={cx('d-flex', 'flex-column', 'justify-content-center', 'align-items-center')}>
              <FontAwesomeIcon icon={faExclamationCircle} size="5x" className={cx('mb-4', 'mt-4', 'text-danger')}/>
              <h1>{f({ id: "app.wrong" })}</h1>
              <p className={cx(styles.text, 'text-muted', 'mb-4')}>{f({ id: "app.working" })}</p>
              <button onClick={props.onClick} className={cx('btn', 'btn-primary')}>
                  {f({id: 'app.back'})}
              </button>
        </div>
        <div className={cx(styles.footer,'mt-5')}>
          <span> {f({ id: "app.copyright" })} {f({ id: "app.allrights" })}</span>
        </div>
      </div>

   );
};

export default GenericError;
