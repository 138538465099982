import client from './client';
import moment from 'moment-timezone';
/**
 * Send request
 */
export const sendRequest = async (data) => {
    const dataDTO = data;
    if (dataDTO.materialCollectionDate) {
        dataDTO.materialCollectionDate = getDateDDMMYYYY(dataDTO.materialCollectionDate)
    }
    if (dataDTO.materialReturnDate) {
        dataDTO.materialReturnDate = getDateDDMMYYYY(dataDTO.materialReturnDate)
    }
    if (dataDTO.roomCheckInDate) {
        dataDTO.roomCheckInDate = getDateDDMMYYYY(dataDTO.roomCheckInDate)
    }
    if (dataDTO.roomCheckOutDate) {
        dataDTO.roomCheckOutDate = getDateDDMMYYYY(dataDTO.roomCheckOutDate)
    }
    return await client.post('request', dataDTO ).then(response => response.data);
}   

const getDateDDMMYYYY = (date) => {
    return moment(date).format('DD/MM/YYYY');
}

/**
 * Send pin by SMS
 * @param {*} data 
 * @returns 
 */
export const sendPin = async (data) => client.post('request/send-pin', data).then(response => response.data);

/**
 * Confirm request
 */
export const confirmRequest = async (data) =>
 await client.post('request/confirm', data ).then(response => response.data).catch((e) => { throw new Error(e.response.data.message); });


export const getAvailableCities = async() => 
    await client.get('/available-cities')
    .then(response => response.data)
    .catch((e) => { throw new Error(e.response.data.message);
});