import React from 'react';
import GenericError from 'views/common/error/GenericError';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.onClick = this.onClick.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // También puedes registrar el error en un servicio de reporte de errores
    console.error("======");
    console.error(error);
    console.error(errorInfo);
    //logErrorToMyService(error, errorInfo);
  }

  onClick = () => {
    this.setState({ hasError: false });
  //  push('/');
  }

  render() {
    if (this.state.hasError) {
      // Puedes renderizar cualquier interfaz de repuesto
      return <GenericError onClick={this.onClick}></GenericError>
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
