import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from 'reactstrap';
import boatIcon from 'assets/img/icons/icon-inflatable-boat.png';
import vanIcon from 'assets/img/icons/icon-van.png';
import trailerIcon from 'assets/img/icons/icon-remolque.png';
import {
  getMaterialsByType
} from "state/request";
import {
  materialTypes, materialTypesEnum
} from "utils/Enums";

import Material from './Material';

const MaterialSelector = () => {
  const dispatch = useDispatch();
  const materials = useSelector(state => state.request.materials);
  const loading = useSelector(state => state.request.loading);
  const [currentMaterial, setCurrentMaterial] = useState(materialTypesEnum.BOAT);

  useEffect(() => {
    dispatch(getMaterialsByType(currentMaterial));
  }, [currentMaterial]);
  

  const renderMaterialIcon = (item) => {
    switch (item.code) {
        case materialTypesEnum.BOAT:
          return <img src={boatIcon} width="40" height="40"/>;
        case materialTypesEnum.TRAILER:
          return <img src={trailerIcon} width="40" height="40"/>;
        case materialTypesEnum.VAN:
          return <img src={vanIcon} width="40" height="40"/>;
        default:
          return null;
      }
  }

  const renderMaterialTab = (item) => {
    if (currentMaterial === item.code) {
      return  <li className="nav-item active" key={item.code}>
        <a className="nav-link active" onClick={() => setCurrentMaterial(item.code)} data-bs-toggle="tab">
          <span className="featured-boxes featured-boxes-style-6 p-0 m-0">
            <span className="featured-box featured-box-primary featured-box-effect-6 p-0 m-0">
              <span className="box-content p-0 m-0">
                <i className="icon-featured">{renderMaterialIcon(item)}</i>
              </span>
            </span>
          </span>
          <p className="mb-0 pb-0">{item.description}</p>
        </a>
      </li>;
    } else {
      return  <li className="nav-item" key={item.code}>
        <a className="nav-link" onClick={() => setCurrentMaterial(item.code)} data-bs-toggle="tab">
          <span className="featured-boxes featured-boxes-style-6 p-0 m-0">
            <span className="featured-box featured-box-primary featured-box-effect-6 p-0 m-0">
              <span className="box-content p-0 m-0">
                <i className="icon-featured">{renderMaterialIcon(item)}</i>
              </span>
            </span>
          </span>
          <p className="mb-0 pb-0">{item.description}</p>
        </a>
      </li>;
    }
  }

  return <React.Fragment>
      <div className="col-xs-12 col-lg-8 mb-5 mb-lg-0">
        <div className="row">
          <div className="col">
              <div className="tabs tabs-bottom tabs-center tabs-simple">
                <ul className="nav nav-tabs nav-fill mb-5">
                  {materialTypes.map((item) =>
                    renderMaterialTab(item)
                  )}
                </ul>
                <div className="container-fluid shop">
                  <div className="row portfolio-list sort-destination">
                    {loading ?
                        <div className="d-flex justify-content-center">
                          <Spinner
                            as="span"
                            animation="grow"
                            role="status"
                            className="mr-1"
                            aria-hidden="true"
                            variant="primary"
                          />
                        </div>
                      :
                        materials && materials.map((item, index) =>
                        {
                          return (
                            <div className="col-md-6 col-lg-4 isotope-item rooms mb-3 pb-3" key={index}>
                              <Material 
                                item={item} 
                                index={index} 
                              />
                            </div>
                          )
                        }
                    )}
                  </div>
                </div>
              </div>  
          </div>
        </div>
      </div>
      
  </React.Fragment>
};

export default MaterialSelector;
