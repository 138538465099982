import React from "react";

import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';

const PublicLayout = props => {
  // const error = useSelector(state => state.auth.error);

  // if (error && (error.code === 500 || error === 500)) {
  //   // FIXME dispatch(actions.resetError());
  //   throw new Error(error.message);
  // }

  const Component = props.component;
  const route = props.route;
  ///   const user  = props.user;

  return (
      <>
        <Header />
        <div role="main" className="main shop pb-4">
          <Component route={route} />
        </div>
        <Footer />
      </>
  );
};

export default PublicLayout;
