import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from 'reactstrap';
import {
  getAvailableCities,
  initRequest
} from "state/request";

import {
  materialTypesEnum,
  requestFormSteps,
  requestFormStepsEnum
} from "utils/Enums";

import RequestForm from './components/RequestForm';

import { getLastStep, getNextStep, getPreviousStep, isLastStep } from 'utils/Helpers';

import backgroundImg from "assets/img/header.jpg";

const Request = () => {
  const dispatch = useDispatch();

  const { initializing, confirmed, token } = useSelector(state => state.request);

  const [currentStep, setCurrentStep] = useState(token ? requestFormStepsEnum.DATA : requestFormStepsEnum.MATERIAL);
  const [submittedSteps, setSubmittedSteps] = useState([]);

  useEffect(() => {
    dispatch(initRequest(materialTypesEnum.BOAT));
  }, [dispatch]);

  useEffect(() => {
    if (confirmed) {
      setCurrentStep(requestFormStepsEnum.CONFIRMATION);
    }
  }, [confirmed]);

  useEffect(() => {
    dispatch(getAvailableCities());
  }, []);


  const addSubmittedStep = (submittedStep) => {
    // se comprueba si el array ya contiene el step
    const index = submittedSteps.indexOf(submittedStep);

    if (index === -1) {
      const newSubmittedSteps = submittedSteps;
      newSubmittedSteps.push(submittedStep);

      setSubmittedSteps(newSubmittedSteps);
    }
  }

  const goToNextStep = () => {
    setCurrentStep(getNextStep(currentStep));
  }

  const goToPreviousStep = () => {
    setCurrentStep(getPreviousStep(currentStep));
  }

  const stepNotAllowed = (stepCode) => {
    return token || isLastStep(stepCode) || getLastStep().code === currentStep || !isStepAvailable(stepCode);
  }

  const isStepAvailable = (step) => {
    return submittedSteps.indexOf(step) !== -1;
  }

  return (
    <>
      <div className="page-header page-header-modern page-header-background overlay overlay-color-secondary overlay-show overlay-op-5 m-0 py-0" style={{ background: `url(${backgroundImg})` + "center no-repeat content-box" }}>
        <div className="container py-3">
          <div className="row py-3">
            <div className="col-md-12 align-self-center p-static text-center">
              <h1 className="text-light mt-4 mb-0 pb-0 font-weight-bold text-8">Solicitud de Reserva</h1>
              <div className="divider divider-light divider-small my-3 text-center">
                <hr className="mt-2 mx-auto" />
              </div>
            </div>
            <div className="col-md-12 align-self-center">
              <ul className="breadcrumb breadcrumb-light d-block mb-4 text-center">
                <li><a href="#">Inicio</a></li>
                <li className="active">Solicitud de reserva</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {!initializing ?
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12">
              <ul className="breadcrumb font-weight-bold text-6 justify-content-center my-5">
                {requestFormSteps.map((item, index) =>
                  <li className="text-transform-none me-2" key={index}>
                    <a
                      onClick={() => {
                        if (!stepNotAllowed(item.code)) {
                          setCurrentStep(item.code);
                        }
                      }}
                      className="breadcrumb-request text-decoration-none"
                      style={{ color: item.code === currentStep && "#00a1cd", cursor: stepNotAllowed(item.code) ? 'not-allowed' : 'pointer' }}
                      disabled={stepNotAllowed(item.code)}>
                      {item.description}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <RequestForm
            goToPreviousStep={goToPreviousStep}
            goToNextStep={goToNextStep}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            addSubmittedStep={addSubmittedStep}
          />
        </div >
        :
        <div className="d-flex justify-content-center">
          <Spinner
            as="span"
            animation="grow"
            role="status"
            className="mr-1"
            aria-hidden="true"
            variant="primary"
          />
        </div>
      }

    </>
  );
};

export default Request;
