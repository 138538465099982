import React from 'react';

import {ReactComponent as NavigationIcon} from 'assets/img/icons/navigation.svg';

import logoRFEV from "assets/img/logo-esp-sailing-team-pie.png";

const Footer = () => {

  return (
		<div id="footer" className="footer-reveal bg-color-secondary border-0 mt-0">
			<div className="container container-xl-custom pt-4 pb-3">
				<div className="row py-5">
					<div className="col-md-4 col-lg-3">
						<a href="https://www.rfev.es/">
							<img alt="RFEV" width="95" height="60" src={logoRFEV} />
						</a>
						<ul className="social-icons social-icons-clean social-icons-medium">
							<li>
								<a href="https://www.instagram.com/oficial_rfev/?hl=es"><i className="fab fa-instagram"/></a>
							</li>
							<li>
								<a href="https://twitter.com/oficial_rfev"><i className="fab fa-twitter"/></a>
							</li>
							<li>
								<a href="https://es-es.facebook.com/RFEVoficial"><i className="fab fa-facebook-f"/></a>
							</li>
						</ul>
					</div>
					<div className="col-md-4 col-lg-3 mb-4 mb-md-0">
						<h4 className="font-weight-bold ls-0">Catálogo</h4>
						<ul className="list-unstyled mb-0">
							<li className="mb-1">
								<a href={process.env.REACT_APP_REQUEST_APP}>Neumáticas</a>
							</li>
							<li className="mb-1">
								<a href={process.env.REACT_APP_REQUEST_APP}>Furgonetas</a>
							</li>
							<li className="mb-0">
								<a href={process.env.REACT_APP_REQUEST_APP}>Remolques</a>
							</li>
							<li className="mb-0">
								<a href={process.env.REACT_APP_REQUEST_APP}>Alojamiento</a>
							</li>
						</ul>
						<a href={process.env.REACT_APP_REQUEST_APP} className="btn btn-link font-weight-bold text-decoration-none ps-0">VER TODO</a>
					</div>
					<div className="col-md-4 col-lg-3 mb-4 mb-md-0">
						<h4 className="font-weight-bold ls-0">Información</h4>
						<ul className="list-unstyled mb-0">
							<li className="mb-1">
								<a rel='noopener noreferrer' href="https://www.rfev.es/">RFEV.ES</a>
							</li>
							<li className="mb-0">
								<a rel='noopener noreferrer' href="https://logistica.rfev.es/#acerca-de">Acerca de</a>
							</li>
							<li className="mb-0">
								<a rel='noopener noreferrer' href="https://logistica.rfev.es/#como-funciona">Cómo funciona</a>
							</li>
							<li className="mb-0">
								<a rel='noopener noreferrer' href="https://logistica.rfev.es/#faq">FAQ</a>
							</li>
						</ul>
					</div>
					<div className="col-md-4 col-lg-3 mb-4 mb-lg-0">
						<h4 className="font-weight-bold ls-0">Contacto</h4>
						<div className="feature-box feature-box-style-2 align-items-center mb-3">
							<div className="feature-box-icon">
								<div className="animated-icon animated fadeIn svg-fill-color-light">
									{/* <PhoneIcon className="icon-animated svg-fill-color-tertiary position-relative bottom-3" alt="Teléfono de contacto" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-tertiary position-relative bottom-3'}"  /> */}
									<NavigationIcon data-icon="" />

									{/* <img className="icon-animated" width="45" src={logoNavigation} alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light'}" /> */}
								</div>
							</div>
							<div className="feature-box-info ps-2">
								<p className="text-uppercase font-weight-semibold line-height-1 text-2 pb-0 mb-0">¿Tienes dudas?</p>
								<a href="mailto:logistica@rfev.es" className="text-uppercase text-color-light text-color-hover-secondary text-decoration-none text-5 font-weight-bold pb-0">logistica@rfev.es</a>
							</div>
						</div>
						<a href={process.env.REACT_APP_REQUEST_APP} className="btn btn-primary font-weight-bold px-5 py-3 mb-2">RESERVAR</a>
					</div>
				</div>
			</div>
			<div className="footer-copyright bg-color-secondary">
				<div className="container container-xl-custom pb-4">
					<div className="row">
						<div className="col">
							<hr className="my-0 bg-color-light opacity-1" />
						</div>
					</div>
					<div className="row py-4 mt-2">
						<div className="col-12 text-center">
							<p className="text-3 mb-0">RFEV. © 2023. Todos los derechos reservados. Aviso Legal. Privacidad.</p>
						</div>
					</div>
				</div>
			</div>
		</div>

  );

}

export default Footer;