import { useDispatch, useSelector } from "react-redux";

import moment from 'moment';

import {
	removeMaterial,
	removeRoomRequest
} from "state/request";

import { getMaterialTypeDescription } from "utils/Helpers";

const Summary = (props) => {

	const dispatch = useDispatch();

	const selectedMaterials = useSelector(state => state.request.selectedMaterialsAndAmounts);
	const materialRequest = useSelector(state => state.request.materialRequest);
	const roomRequest = useSelector(state => state.request.roomRequest);

	/**
	 * Returns the icon according to the guest's gender
	 * @param {object|string} gender: the guests's gender
	 * @returns the icon according to the guest's gender
	 */
	const getGuestGenderIcon = (gender) => {
		if (gender.value) {
			gender = gender.value
		} // if

		let className = '';

		if (gender === 'M') {
			className = "las la-mars";
		} else if (gender === 'F') {
			className = "las la-venus";
		} // if-else if

		return <i className={className} style={{ fontSize: '20px', marginRight: '10px' }} />;
	} // getGuestGenderIcon

	/**
	 * Returns the guest's position as a string
	 * @param {object|string} position: the guest's position
	 * @returns the guest's position as a string
	 */
	const getGuestPosition = (position) => {
		// If the position is an object, it gets its value
		if (position.value) {
			position = position.value;
		} // if

		return position;
	} // getGuestPosition

	const getDateAndPlaceInformation = (date, place) => {
		return `El día ${moment(date).format('DD/MM/YYYY')} en ${place}`
	}

	const getRoomRequestInformation = (roomRequest) => {
		return `Del ${moment(roomRequest.roomCheckInDate).format('DD/MM/YYYY')} al ${moment(roomRequest.roomCheckOutDate).format('DD/MM/YYYY')}`;
	}

	const getGuestSummaryInfo = (guest) => {
		let info = '';
		info = `${guest.firstName} ${guest.lastName} -`
		if (guest.dni) {
			info += `${guest.dni} -`
		}
		info += `${getGuestPosition(guest.position)}`
		return info;
	}

	return (
		<>
			<h4 className="font-weight-bold text-uppercase text-4 mb-3">Tu pedido</h4>
			{
				selectedMaterials.length > 0 &&
				<table className="shop_table cart-totals mb-4">
					<tbody>
						<tr>
							<td colSpan="2" className="border-top-0">
								<strong className="text-color-primary">
									Material
								</strong>
							</td>
						</tr>
						{
							selectedMaterials.map((item) => {
								return (
									<tr className="cart-subtotal" key={item.id}>
										<td>
											<strong className="d-block text-color-dark line-height-1 font-weight-semibold">
												{getMaterialTypeDescription(item.type)}
											</strong>
											<span className="text-1">{item.description}</span>
										</td>
										<td className="border-top-0 text-end">
											<span className="amount font-weight-medium px-4">
												x{item.amount}
											</span>
											<button type="button" className="btn" onClick={() => dispatch(removeMaterial(item.id))}>
												<i className="fas fa-trash icons text-dark text-3" />
											</button>
										</td>
									</tr>
								);
							})}
					</tbody>
					{
						materialRequest && props.view !== 'material' &&
						<tbody>
							<tr className="cart-subtotal">
								<td className="border-top-0">
									<strong className="text-color-secondary">
										Concentración/Competición
									</strong>
								</td>
							</tr>
							<tr>
								<td><strong className="d-block line-height-1 font-weight-semibold"><span className="amount font-weight-medium">{materialRequest.materialEventName}</span></strong></td>
							</tr>
							<tr className="cart-subtotal">
								<td className="border-top-0"><strong className="text-color-secondary">Recogida de material</strong></td>
							</tr>
							<tr>
								<td>
									<strong className="d-block line-height-1 font-weight-semibold">
										<span className="amount font-weight-medium">
											{getDateAndPlaceInformation(materialRequest.materialCollectionDate, materialRequest.materialCollectionPlace)}
										</span></strong></td>
							</tr>
							<tr className="cart-subtotal">
								<td className="border-top-0"><strong className="text-color-secondary">Entrega de material</strong></td>
							</tr>
							<tr>
								<td>
									<strong className="d-block line-height-1 font-weight-semibold">
										<span className="amount font-weight-medium">
											{getDateAndPlaceInformation(materialRequest.materialReturnDate, materialRequest.materialReturnPlace)}
										</span>
									</strong>
								</td>
							</tr>
						</tbody>
					}
				</table>}
			{
				selectedMaterials.length === 0 &&
				<div className="mb-5">No hay material seleccionado</div>
			}
			{
				roomRequest?.guests.length > 0 &&
				<table className="shop_table cart-totals mb-4">
					<tbody>
						<tr><td colSpan="2" className="border-top-0"><strong className="text-color-primary">Habitaciones</strong></td></tr>

						<tr className="cart-subtotal">
							<td>
								<strong className="d-block text-color-dark line-height-1 font-weight-semibold">Camas</strong>
								<span className="text-1">
									{getRoomRequestInformation(roomRequest)}
								</span>
							</td>
							<td className="border-top-0 text-end">
								<span className="amount font-weight-medium px-4">
									x{roomRequest.roomNumberOfBeds}
								</span>
								<button
									type="button"
									className="btn"
									onClick={() => dispatch(removeRoomRequest())}
								>
									<i className="fas fa-trash icons text-dark text-3" />
								</button>
							</td>
						</tr>
						<tr>
							<td>
								<strong className="d-block text-color-dark line-height-1 font-weight-semibold">Huéspedes</strong>
							</td>
						</tr>
						{
							roomRequest.guests.map(guest => {
								return (
									<tr>
										<td>
											<div className="text-align-center">

												{getGuestGenderIcon(guest.gender)}
												{getGuestSummaryInfo(guest)}
											</div>
										</td>
									</tr>
								); // return
							}) // map
						} {/* roomRequest.guests */}
					</tbody>
				</table>
			}
		</>
	); // return
}; // Summary

export default Summary;
