import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import styles from "./Input.module.css";


const Input = (props) => {
    const [units, setUnits] = useState(1);

    useEffect(() => {
      props.onChange(units);
    }, [units]);

    const handleChange = event => {
      setUnits(event.target.value);
    };

    const minusClick = () => {
      if (units > 0) {
        setUnits(units - 1);
      }
      else {
        setUnits(0);
      }
    };

    const plusClick = () => {
      setUnits(units + 1);
    };

    return (
      <div className="quantity quantity-lg">
        <input type="button" onClick={minusClick} value="-" className="minus text-color-hover-light bg-color-hover-primary border-color-hover-primary" data-field="quantity"/>
        <input type="number" onChange={handleChange} step="1" max="" minux="0" value={units} name="quantity" className="input-text qty text"/>
        <input type="button" onClick={plusClick} value="+" className="plus text-color-hover-light bg-color-hover-primary border-color-hover-primary" data-field="quantity"/>
      </div>
   );
};

export default Input;
