import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import {
  addMaterialAndAmount
} from "state/request";

import InputMinusPlus from 'components/input-minus-plus';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { buildYesOrNo } from "utils/Helpers";

const materialFilesURL = process.env.REACT_APP_MATERIAL_FILES_URL;
const boatDefaultImage = process.env.REACT_APP_MATERIAL_BOAT_DEFAULT_IMAGE;
const vanDefaultImage = process.env.REACT_APP_MATERIAL_VAN_DEFAULT_IMAGE;
const trailerDefaultImage = process.env.REACT_APP_MATERIAL_TRAILER_DEFAULT_IMAGE;

const Material = ({
  item,
  index
}) => {
  const dispatch = useDispatch();
  const [units, setUnits] = useState(0);

  const onChangeUnits = (units) => {
    setUnits(units);
  }

  const addMaterial = () => {
    if (units > 0) {
      dispatch(addMaterialAndAmount({
        "material": item, "amount": units
      }));
    }
  }

  const renderMaterialDetails = (item) => {

    if (!item) {
      return null;
    }
    switch (item.type) {
      case 'BOAT':
        return (<ul className="mb-0">
          <li><div className="tag">ESLORA</div><span>{item.length}</span></li>
          <li><div className="tag">MANGA</div><span>{item.sleeve}</span></li>
          <li><div className="tag">PESO</div><span>{item.weight}</span></li>
          <li><div className="tag">CAPACIDAD</div><span>{item.numberOfSeats}</span></li>
          <li><div className="tag">POTENCIA MÁX</div><span>{item.power}</span></li>
          {item.datasheet && <li className="text-color-primary">
            <label>FICHA TÉCNICA</label>
            <span>
              <a
                href={`${materialFilesURL}${item.id}/pdf/${item.datasheet}`}
                target="_blank">
                <strong>PDF</strong>
              </a>
            </span>
          </li>}

        </ul>);
      case 'TRAILER':
        return <ul className="mb-0">
          <li><div className="tag">POTENCIA MÁX</div><span>{item.power}</span></li>
          <li><div className="tag"></div><span></span></li>
        </ul>;
      case 'VAN':
        return <ul className="mb-0">
          <li><div className="tag">Adaptada</div><span>{buildYesOrNo(item.adapted)}</span></li>
          <li><div className="tag">Dimensiones</div><span>{item.measurements}</span></li>
          <li><div className="tag">Nº de plazas</div><span>{item.numberOfSeats}</span></li>
          <li><div className="tag">Baca</div><span>{buildYesOrNo(item.hasRoofRack)}</span></li>
          <li><div className="tag">Bola remolque</div><span>{buildYesOrNo(item.hasCouplingBall)}</span></li>
        </ul>;
      default:
        return null;
    }
  }

  const renderMaterialPhotos = (item) => {

    if (item.photos.length > 0) {
      return <Carousel showThumbs={false} showStatus={false}>
        {item.photos.map((photo, index) => {
          return (<div key={index} className="carousel-img-container">
            <img className="rounded" src={`${materialFilesURL}${item.id}/images/${photo.file}`} />
          </div>)
        })}

      </Carousel>
    } else {
      switch (item.materialType) {
        case 'BOAT':
          return <Carousel showThumbs={false} showStatus={false}>
            <div className="carousel-img-container">
              <img className="rounded" src={`${materialFilesURL}default/${boatDefaultImage}`} />
            </div>
          </Carousel>;
        case 'TRAILER':
          return <Carousel showThumbs={false} showStatus={false}>
            <div className="carousel-img-container">
              <img className="rounded" src={`${materialFilesURL}default/${trailerDefaultImage}`} />
            </div>
          </Carousel>;
        case 'VAN':
          return <Carousel showThumbs={false} showStatus={false}>
            <div className="carousel-img-container">
              <img className="rounded" src={`${materialFilesURL}default/${vanDefaultImage}`} />
            </div>
          </Carousel>;
        default:
          return null;
      }
    }

  }

  return <React.Fragment key={index}>
    {renderMaterialPhotos(item)}
    <div className="text-center">
      <h5 className="text-transform-none text-4 font-weight-bold mt-3 mb-0"> {item.brand} {item.model}</h5>
      <div className="custom-room-suite-info mb-5 mb-lg-0">
        {renderMaterialDetails(item)}
        <hr className="mt-0" />
        <InputMinusPlus onChange={onChangeUnits} />
        <button
          type="button"
          className="btn btn-primary btn-modern text-uppercase bg-color-hover-primary border-color-hover-primary float-end"
          onClick={addMaterial}>Añadir</button>
      </div>
    </div>
  </React.Fragment>
};

export default Material;
