
export const POSITIONS = [
    {
        label: 'Deportista DAN',
        value: 'Deportista DAN'
    },
    {
        label: 'Deportista',
        value: 'Deportista'
    },
    {
        label: 'Técnico',
        value: 'Técnico'
    },
    {
        label: 'Directivo',
        value: 'Directivo'
    },
    {
        label: 'Personal RFEV',
        value: 'Personal RFEV'
    },
]

export const GENDERS = [
    {
        label: 'Hombre',
        value: 'M',
    },

    {
        label: 'Mujer',
        value: 'F'
    }
]

export const SELECT_STYLES = {
    placeholder: (provided) => ({
      ...provided,
      color: '#a9aab4',
      marginLeft: '0.75rem'
    }),
    control: (provided, state) => ({
      ...provided,
      height: 'calc(1.8em + 1.85rem + 2px)',
      border: '1px solid #ebebeb',
    }),
    singleValue: (provided) => ({
      ...provided,
      marginLeft: '0.75rem'
    }),
    menu: provided => ({ ...provided, zIndex: 9999 })
  }