import axios from 'axios';

const TOKEN = process.env.REACT_APP_TOKEN;
const isDevelopment = ("development" === process.env.NODE_ENV);

const axiosClient = () => {
  const defaultOptions = {
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
    timeout: process.env.REACT_APP_API_TIMEOUT,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (isDevelopment) {
    defaultOptions['headers']['Authorization'] = `Bearer ${TOKEN}`;
  }

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    if (isDevelopment) {
      config.headers.Authorization =  `Bearer ${TOKEN}`;
    }
    return config;
  });

  // // Function that will be called to refresh authorization
  // const refreshAuthLogic = failedRequest => instance.post('refresh-token', {refreshToken: Cookies.get("cbGbcRf")}).then(tokenRefreshResponse => {
  //     sessionStorage.setItem('token', tokenRefreshResponse.data.token);
  //     Cookies.set('cbGbcRf', tokenRefreshResponse.data.refreshToken, { expires: tokenRefreshResponse.data.expiresAt });
  //     failedRequest.response.config.headers.Authorization = 'Bearer ' + tokenRefreshResponse.data.token;
  //     return Promise.resolve();
  // });

  // // Instantiate the interceptor (you can chain it as it returns the axios instance)
  // createAuthRefreshInterceptor(instance, refreshAuthLogic);

  return instance;
};

export default axiosClient();
