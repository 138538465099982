import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { resetOperationOk } from 'state/request';
import { getMaterialTypeDescription } from "utils/Helpers";

const Confirmation = () => {
  const dispatch = useDispatch();

  const request = useSelector(state => state.request);

  const [summary, setSummary] = useState(undefined);

  useEffect(() => {
    setSummary({
      selectedMaterials: request.selectedMaterialsAndAmounts,
      materialRequest: request.materialRequest,
      roomRequest: request.roomRequest,
      dataRequest: request.dataRequest,
      request: request,
      locator: request.locator
    });

    dispatch(resetOperationOk());
  }, []);

  return (
    <Fragment>
      <div className="col-xs-12 col-lg-12 px-lg-3 d-flex justify-content-center">
        <div className="card border-width-3 border-radius-0 border-color-success w-75">
          <div className="card-body text-center">
            <p className="text-color-dark font-weight-bold text-4-5 mb-0">
              <i className="fas fa-check text-color-success me-1" />  Gracias. Tu solicitud ha sido enviada con éxito.
            </p>
          </div>
        </div>
      </div>
      {summary && <Fragment><div className="d-flex flex-column flex-md-row justify-content-between py-3 px-4 my-4">
        <div className="text-center">
          <span>
            Número de solicitud <br />
            <strong className="text-color-dark">{summary.locator}</strong>
          </span>
        </div>
        <div className="text-center mt-4 mt-md-0">
          <span>
            Fecha y hora<br />
            <strong className="text-color-dark">{moment().format('DD/MM/YYYY HH:mm')}</strong>
          </span>
        </div>
        <div className="text-center mt-4 mt-md-0">
          <span>
            Email <br />
            <strong className="text-color-dark">{summary.dataRequest.email}</strong>
          </span>
        </div>
        <div className="text-center mt-4 mt-md-0">
          <span>
            Teléfono <br />
            <strong className="text-color-dark">{summary.dataRequest.phoneNumber}</strong>
          </span>
        </div>
      </div>
        <div className="col-xs-12 col-lg-12 px-lg-3">
          <div className="card border-width-3 border-radius-0 border-color-hover-dark mb-4">
            <div className="card-body">
              <h4 className="font-weight-bold text-uppercase text-4 mb-3">Resumen de tu solicitud</h4>
              {summary.selectedMaterials.length > 0 ?
                <table className="shop_table cart-totals mb-4">
                  <tbody>
                    <tr><td colSpan="2" className="border-top-0"><strong className="text-color-primary">Material</strong></td></tr>
                    {summary.selectedMaterials.map((item, key) => {
                      return <tr className="cart-subtotal" key={key}>
                        <td><strong className="d-block text-color-dark line-height-1 font-weight-semibold">{getMaterialTypeDescription(item.type)} {item.description} <span className="product-qty"> x{item.amount} </span></strong>
                          <span className="text-2">REC: {moment(summary.materialRequest.materialCollectionDate).format('DD/MM/YYYY')} {summary.materialRequest.materialCollectionPlace} - ENT: {moment(summary.materialRequest.materialReturnDate).format('DD/MM/YYYY')} {summary.materialRequest.materialReturnPlace}</span>
                        </td>
                      </tr>
                    })}
                  </tbody>
                  {summary.materialRequest && <tbody>
                    <tr className="cart-subtotal">
                      <td className="border-top-0"><strong className="text-color-secondary">Concentración</strong></td>
                    </tr>
                    <tr>
                      <td><strong className="d-block line-height-1 font-weight-semibold"><span className="amount font-weight-medium">{summary.materialRequest.materialEventName}</span></strong></td>
                    </tr>
                  </tbody>
                  }
                </table>
                :
                <div className="mb-5">No hay material seleccionado</div>
              }
              {summary.roomRequest &&
                <>
                  <table className="shop_table cart-totals mb-4">
                    <tbody>
                      <tr>
                        <td colSpan="2" className="border-top-0"><strong className="text-color-primary">Alojamiento</strong></td>
                      </tr>
                      <tr className="cart-subtotal">
                        <td>
                          <strong className="d-block text-color-dark line-height-1 font-weight-semibold">Camas <span className="product-qty"> x{summary.roomRequest.roomNumberOfBeds} </span></strong>
                          <span className="text-2">Del {moment(summary.roomRequest.roomCheckInDate).format('DD/MM/YYYY')} al {moment(summary.roomRequest.roomCheckOutDate).format('DD/MM/YYYY')}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong className="d-block text-color-dark line-height-1 font-weight-semibold">Huéspedes</strong>
                        </td>
                      </tr>
                      {
                        summary.roomRequest.guests.map(guest => {
                          return (
                            <tr>
                              <td>
                                <div className="text-align-center">
                                  {`${guest.firstName} ${guest.lastName} - ${guest.dni} - ${guest.position}`}
                                </div>
                              </td>
                            </tr>
                          ); // return
                        }) // map
                      } {/* roomRequest.guests */}
                    </tbody>

                  </table>
                </>
              }
              <p>Los datos mostrados en esta solicitud están sujetos a disponibilidad y no tienen carácter contractual. Se le enviará una confirmación en caso de disponer de los productos indicados o, en su contra, una contraoferta con las alternativas más similares.</p>
            </div>
          </div>
        </div>
      </Fragment>
      }
    </Fragment>
  );
};

export default Confirmation;