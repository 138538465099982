
export const materialTypesEnum = {
  BOAT: 'BOAT',
  TRAILER: 'TRAILER',
  VAN: 'VAN'
}

export const materialTypes = [
  {code: materialTypesEnum.BOAT, description: 'Lancha neumática'},
  {code: materialTypesEnum.TRAILER, description: 'Remolque'},
  {code: materialTypesEnum.VAN, description: 'Furgoneta'}
];


export const requestFormStepsEnum = {
  MATERIAL: 'MATERIAL',
  ROOMS: 'ROOMS',
  DATA: 'DATA',
  CONFIRMATION: 'CONFIRMATION'
}

export const requestFormSteps = [
  {code: requestFormStepsEnum.MATERIAL, description: 'Material'},
  {code: requestFormStepsEnum.ROOMS, description: 'Alojamiento'},
  {code: requestFormStepsEnum.DATA, description: 'Datos del solicitante'},
  {code: requestFormStepsEnum.CONFIRMATION, description: 'Confirmación'}
];
