import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorkerRegistration';

import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './state/configureStore';
import { locales } from "./i18n";
import App from './views/App';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';


const rootNode = document.getElementById('root');
ReactDOM.render(   <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider locale={"es"} messages={locales["es"]} defaultLocale="es">
            <Router>
             <App/>
            </Router>
        </IntlProvider>
        </PersistGate>
    </Provider>
, rootNode);

serviceWorker.register();