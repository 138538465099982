import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { FormText } from 'reactstrap';
import {
  confirmRequest,
  sendPin,
  setDataRequest
} from "state/request";
import * as Yup from "yup";

import { validateIdentityDocument } from 'validators/identityDocumentValidator';
import Summary from './Summary';

const DataForm = (props) => {

  const PHONE_REGEX = /^[6-7]{1}[0-9]{8}$/;
  const EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();

  const token = useSelector(state => state.request.token);
  const locator = useSelector(state => state.request.locator);
  const saving = useSelector(state => state.request.saving);
  const sendingSms = useSelector(state => state.request.sendingSms);
  const error = useSelector(state => state.request.error);

  const REQUIRED_ERROR_LABEL = f({ id: "form.errors.required" });
  const FORMAT_ERROR_LABEL = f({ id: "form.errors.format" });
  const INVALID_ID_NUMBER_LABEL = f({ id: 'form.errors.dni' });
  const INVALID_MIN_LENGTH_NAME_LABEL = f({ id: 'form.errors.name.min' });
  const INVALID_MAX_LENGTH_NAME_LABEL = f({ id: 'form.errors.name.max' });
  const INVALID_NAME_LABEL = f({ id: 'form.errors.name.numbers' });
  const INVALID_MIN_LENGTH_SURNAME_LABEL = f({ id: 'form.errors.name.min' });
  const INVALID_MAX_LENGTH_SURNAME_LABEL = f({ id: 'form.errors.name.max' });
  const INVALID_SURNAME_LABEL = f({ id: 'form.errors.name.numbers' });

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(REQUIRED_ERROR_LABEL)
      .min(3, INVALID_MIN_LENGTH_NAME_LABEL)
      .max(50, INVALID_MAX_LENGTH_NAME_LABEL)
      .matches(/^[A-Za-záéíóúÁÉÍÓÚüÜñÑ\s]+$/, INVALID_NAME_LABEL),

    surname: Yup.string()
      .ensure()
      .required(REQUIRED_ERROR_LABEL)
      .min(3, INVALID_MIN_LENGTH_SURNAME_LABEL)
      .max(50, INVALID_MAX_LENGTH_SURNAME_LABEL)
      .matches(/^[A-Za-záéíóúÁÉÍÓÚüÜñÑ\s]+$/, INVALID_SURNAME_LABEL),

    identityDocument: Yup.string()
      .ensure()
      .required(REQUIRED_ERROR_LABEL)
      .test(
        {
          test: (v) => validateIdentityDocument(v),
          message: INVALID_ID_NUMBER_LABEL
        }
      ),

    phoneNumber: Yup.string()
      .ensure()
      .required(REQUIRED_ERROR_LABEL)
      .matches(PHONE_REGEX, FORMAT_ERROR_LABEL),

    email: Yup.string()
      .ensure()
      .required(REQUIRED_ERROR_LABEL)
      .matches(EMAIL_REGEX, FORMAT_ERROR_LABEL),

    accept: Yup.bool().oneOf([true], f({ id: "form.errors.accept" }))
  }); // validationSchema

  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    mode: "onBlur",
    // TODO: find another way so as not to use sessionStorage
    defaultValues: JSON.parse(sessionStorage.getItem('applicantData')),
    resolver: yupResolver(validationSchema)
  }); // useForm

  const onSubmit = (data) => {
    sessionStorage.setItem('applicantData', null);
    dispatch(setDataRequest(data));
  } // onSubmit

  const onSendPin = (pin) => {
    setValue('pin', null);
    dispatch(confirmRequest(pin));
  } // onSendPin

  /**
   * Stores the introduced data to avoid losing them when going a step back or refreshing the page
   */
  useEffect(() => {
    return () => {
      // TODO: find another way so as not to  use sessionStorage
      sessionStorage.setItem('applicantData', JSON.stringify(getValues()));
    }
  }, [getValues]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="row">
          <div className="col-xs-12 col-lg-8 ">
            <h2 className="text-color-dark font-weight-bold text-5-5 mb-3">Datos del solicitante</h2>
            <div className="row">
              <div className="form-group col">
                <label className="form-label" htmlFor="identityDocument">Documento de identidad <span className="text-color-danger">*</span></label>
                <input
                  name="identityDocument"
                  id="identityDocument"
                  {...register('identityDocument')}
                  className="form-control h-auto py-2"
                />
                {errors.identityDocument && (
                  <FormText color="red" className="text-danger">
                    {errors.identityDocument.message}
                  </FormText>
                )}
              </div>
              <div className="form-group col">
                <label className="form-label" htmlFor="name">Nombre <span className="text-color-danger">*</span></label>
                <input
                  name="name"
                  id="name"
                  autoComplete="given-name"
                  {...register('name')}
                  className="form-control h-auto py-2"
                />
                {errors.name && (
                  <FormText color="red" className="text-danger">
                    {errors.name.message}
                  </FormText>
                )}
              </div>
              <div className="form-group col">
                <label className="form-label" htmlFor="surname">Apellidos <span className="text-color-danger">*</span></label>
                <input
                  name="surname"
                  id="surname"
                  {...register('surname')}
                  className="form-control h-auto py-2"
                />
                {errors.surname && (
                  <FormText color="red" className="text-danger">
                    {errors.surname.message}
                  </FormText>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label" htmlFor="phoneNumber">Teléfono <span className="text-color-danger">* (A efectos de notificación SMS)</span></label>
                <input
                  name="phoneNumber"
                  id="phoneNumber"
                  {...register('phoneNumber')}
                  className="form-control h-auto py-2"
                />
                {errors.phoneNumber && (
                  <FormText color="red" className="text-danger">
                    {errors.phoneNumber.message}
                  </FormText>
                )}
              </div>
              <div className="form-group col">
                <label className="form-label" htmlFor="email">Email <span className="text-color-danger">*</span></label>
                <input
                  name="email"
                  id="email"
                  {...register('email')}
                  autoComplete="off"
                  className="form-control h-auto py-2"
                />
                {errors.email && (
                  <FormText color="red" className="text-danger">
                    {errors.email.message}
                  </FormText>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label" htmlFor="observations">Observaciones</label>
                <textarea
                  name="observations"
                  id="observations"
                  {...register('observations')}
                  rows="5"
                  className="form-control h-auto py-2"
                  placeholder="Escribe las notas o comentarios que consideres necesario para tu reserva..."
                />
                {errors.observations && (
                  <FormText color="red" className="text-danger">
                    {errors.observations.message}
                  </FormText>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <div className="custom-checkbox-1" data-bs-toggle="collapse" data-bs-target=".shipping-field-wrapper">
                  <input
                    id="accept"
                    type="checkbox"
                    name="accept"
                    value="1"
                    {...register('accept')}
                  />
                  <label htmlFor="accept">Acepto los términos y condiciones y que mis datos sean tratados para atender mi solicitud, sin que se cedan a terceros. </label>
                </div>
                {errors.accept && (
                  <FormText color="red" className="text-danger">
                    {errors.accept.message}
                  </FormText>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4 position-relative">
            <div className="pinWrapper">
              <div className="card border-width-3 border-radius-0 border-color-hover-secondary">
                <div className="card-body">
                  <Summary
                    currentStep={props.currentStep}
                    register={register}
                    setValue={setValue}
                    getFormValues={getValues} />
                  <p className="text-1">
                    Los datos mostrados en esta solicitud están sujetos a disponibilidad y no tienen carácter contractual.
                    Se le enviará una confirmación en caso de disponer de los productos indicados o, en su contra,
                    una contraoferta con las alternativas más similares.
                  </p>
                  {token ?
                    <React.Fragment>
                      <div>
                        <p>Introduzca el pin que ha recibido por SMS</p>
                        <input
                          name="pin"
                          id="pin"
                          {...register('pin')}
                          placeholder="PIN"
                          className="form-control input-pin h-auto py-2 mr-3"
                        />
                        <button
                          type="button"
                          className="btn btn-secondary btn-modern text-uppercase bg-color-hover-primary border-color-hover-primary border-radius-0 text-3 py-3"
                          disabled={saving}
                          onClick={() => onSendPin(getValues('pin'))}>
                          {saving ? "ENVIANDO..." : "FINALIZAR"}
                        </button>
                      </div>
                      {error && (
                        <FormText color="red" className="text-danger">
                          {error}
                        </FormText>
                      )}
                      <div className="mt-3">
                        <span className="text-1">
                          ¿No lo has recibido?
                        </span>
                        <button type="button" onClick={() => !sendingSms && dispatch(sendPin(locator))} disabled={sendingSms} className="text-1 text-color-tertiary text-color-hover-primary text-decoration-none font-weight-bold btn-link">
                          {sendingSms ? "Enviando..." : "Volver a enviar"}
                        </button>
                      </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <button
                        type="submit"
                        className="btn btn-secondary btn-modern w-100 text-uppercase bg-color-hover-primary border-color-hover-primary border-radius-0 text-3 py-3"
                        disabled={saving}>
                        {saving ?
                          "Enviando SMS..."
                          :
                          "Confirmar y enviar pin por SMS"
                        }
                      </button>
                      <p className="text-1">
                        <em>Se le enviará un SMS con un pin de verificación que deberá introducir a continuación.</em>
                      </p>
                    </React.Fragment>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DataForm;
