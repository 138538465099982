import React, { useState } from 'react';

import { ReactComponent as PhoneIcon } from 'assets/img/icons/phone.svg';
import logoRFEV from 'assets/img/logo.png';
import {
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarText
} from 'reactstrap';

const LANDING = process.env.REACT_APP_LANDING;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <Navbar id="header" expand="md" className="px-lg-4">
        <NavbarBrand className="me-auto" href="/"><div className="header-column header-column-border-right flex-grow-0 navbar-brand"><div className="header-logo"><img alt="RFEV LOGO" width="120" height="auto" data-sticky-width="82" data-sticky-height="40" src={logoRFEV} /></div></div></NavbarBrand>
        <button className="navbar-toggler" type="button" onClick={toggle}>
         <i className="fa fa-bars py-1"/>
        </button>
        <Collapse isOpen={isOpen} navbar>
         <NavbarText className="me-auto" ></NavbarText>
         <Nav navbar>
            <NavItem>
              <NavLink href={LANDING + '/#acerca-de'}>Acerca de</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={LANDING + '/#como-funciona'}>Cómo funciona</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={LANDING + '/#servicios'}>Servicios</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={LANDING + '/#faq'}>FAQ</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={LANDING + '/#contacto'}>Contacto</NavLink>
            </NavItem>
          </Nav>
          <div className="feature-box justify-content-center ms-lg-4"><a className="custom-header-top-btn-style-1 btn btn-secondary font-weight-bold px-4 px-sm-5 py-3">RESERVAR</a></div>
             
          <div className="feature-box feature-box-style-2 align-items-center ms-lg-4 mx-3">
                  <div className="feature-box-icon">
                    <a href="tel:+34942226298">
                      <PhoneIcon className="icon-animated svg-fill-color-tertiary position-relative bottom-3" alt="Teléfono de contacto" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-tertiary position-relative bottom-3'}"  />
                    </a>
                  </div>
                  <div className="feature-box-info ps-2 d-none d-xl-block">
                      <p className="font-weight-semibold line-height-1 text-2 pb-0 mb-1">LL&Aacute;MANOS</p><a className="text-color-tertiary text-color-hover-primary text-decoration-none font-weight-bold line-height-1 custom-font-size-1 pb-0" href="tel:+34942226298">+34 942 22 62 98</a>
                  </div>
         </div>
        </Collapse>
        
      </Navbar>
/*    <div id="header" className="header-effect-shrink" data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': false, 'stickyChangeLogo': true, 'stickyStartAt': 30, 'stickyHeaderContainerHeight': 70}" style={{height: "101px"}}>
      <div className="header-body border-top-0" style={{position: "fixed"}}>
        <div className="header-container container-fluid px-lg-4" style={{height: "100px"}}>
          <div className="header-row">
            <div className="header-column header-column-border-right flex-grow-0">
              <div className="header-row pe-4">
                <div className="header-logo" style={{width: "100px", height: "48px"}}>
                  <a href="/">
                    <img alt="RFEV LOGO" width="120" height="auto" data-sticky-width="82" data-sticky-height="40" src={logoRFEV} />
                  </a>
                </div>
              </div>
            </div>
            <div className="header-column justify-content-center justify-content-md-end">
              <div className="header-row">
                <div className="header-nav header-nav-links justify-content-end">
                  <div className="header-nav-main header-nav-main-text-capitalize header-nav-main-effect-2 header-nav-main-sub-effect-1">
                    <nav className="collapse">
                      <ul className="nav nav-pills" id="mainNav">
                        <li><a href={LANDING + '/#acerca-de'} className="nav-link">Acerca de</a></li>
                        <li><a href={LANDING + '/#como-funciona'} className="nav-link">Cómo funciona</a></li>
                        <li><a href={LANDING + '/#servicios'} className="nav-link">Servicios</a></li>
                        <li><a href={LANDING + '/#faq'} className="nav-link">FAQ</a></li>
                        <li><a href={LANDING + '/#contacto'} className="nav-link">Contacto</a></li>
                      </ul>
                    </nav>
                  </div>
                  <div className="feature-box justify-content-center ms-lg-4"><a className="custom-header-top-btn-style-1 btn btn-secondary font-weight-bold px-4 px-sm-5 py-3">RESERVAR</a></div>
                </div>
                <div className="feature-box feature-box-style-2 align-items-center ms-lg-4 mx-3">
                  <div className="feature-box-icon">
                    <a href="tel:+34942226298">
                      <PhoneIcon className="icon-animated svg-fill-color-tertiary position-relative bottom-3" alt="Teléfono de contacto" data-icon="" data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-tertiary position-relative bottom-3'}"  />
                    </a>
                  </div>
                  <div className="feature-box-info ps-2 d-none d-xl-block">
                      <p className="font-weight-semibold line-height-1 text-2 pb-0 mb-1">LL&Aacute;MANOS</p><a className="text-color-tertiary text-color-hover-primary text-decoration-none font-weight-bold line-height-1 custom-font-size-1 pb-0" href="tel:+34942226298">+34 942 22 62 98</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-column flex-grow-0 justify-content-center header-column-border-left d-lg-none ps-3"><button className="btn header-btn-collapse-nav ms-0" data-bs-toggle="collapse" data-bs-target=".header-nav-main nav"><i className="fas fa-bars py-1"/></button></div>
          </div>
        </div>
      </div>
    </div> */
  );
}

export default Header;