import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";

export const ConfirmationModal = (props) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        p: 4,
    };

    return (
        <Modal isOpen={props.showModal} toggle={props.onToggleClick} className="d-flex align-items-center" style={style}>
            <ModalHeader toggle={props.onToggleClick}>
                {props.header}
            </ModalHeader>
            <ModalBody>
                <div>
                    <span>{props.message}</span>
                    <div className="d-flex justify-content-end mt-20">
                        {
                            props.cancelAction &&
                            <button
                                className="btn btn-shadow"
                                onClick={props.cancelAction}
                            >
                                {props.cancelButtonLabel}
                            </button>
                        } { /* cancelAction */}
                        {props.okAction &&
                            <button
                                className="btn btn-primary buttons-copy buttons-html5 ml-10"
                                onClick={props.okAction}
                            >
                                {
                                    props.loading ?
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                className="mr-1"
                                                aria-hidden="true"
                                                variant="primary"
                                            />
                                            {props.loadingMessage}
                                        </>
                                        :
                                        props.okButtonLabel
                                } { /* Props loading */}
                            </button>
                        } {/* okAction */}
                    </div>
                </div>
            </ModalBody>
        </Modal>
    ); // return
} // ConfirmationModal
