import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { FormText } from 'reactstrap';
import * as Yup from "yup";

import {
  setRoomRequest
} from "state/request";
import { requestFormStepsEnum } from 'utils/Enums';
import { addDaysToDate, findSelectValue, isLastStep } from 'utils/Helpers';

import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { GENDERS, POSITIONS, SELECT_STYLES } from 'utils/constants';
import { validateIdentityDocument } from 'validators/identityDocumentValidator';
import Summary from './Summary';

const roomsFilesURL = process.env.REACT_APP_ROOMS_FILES_URL;

const RoomsForm = (props) => {

  const dispatch = useDispatch();
  const { formatMessage: f } = useIntl();
  const [showDataStepMessageError, setShowDataStepMessageError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const roomRequest = useSelector(state => state.request.roomRequest);
  const materialRequest = useSelector(state => state.request.materialRequest);
  const REQUIRED_ERROR_LABEL = f({ id: "form.errors.required" });
  const NO_OPTIONS_AVAILABLE_LABEL = f({ id: 'form.select.noOptions' });

  const INVALID_ID_NUMBER_LABEL = f({ id: 'form.errors.dni' });
  const INVALID_MIN_LENGTH_NAME_LABEL = f({ id: 'form.errors.name.min' });
  const INVALID_MAX_LENGTH_NAME_LABEL = f({ id: 'form.errors.name.max' });
  const INVALID_NAME_LABEL = f({ id: 'form.errors.name.numbers' });
  const INVALID_MIN_LENGTH_SURNAME_LABEL = f({ id: 'form.errors.name.min' });
  const INVALID_MAX_LENGTH_SURNAME_LABEL = f({ id: 'form.errors.name.max' });
  const INVALID_SURNAME_LABEL = f({ id: 'form.errors.name.numbers' });


  /**
   * Allows to continue to the final step only if any materials and/or rooms are chosen.
   * Otherwise, it won't allow the user to proceed
   * @returns true if either materials or rooms are selected, false otherwise
   */
  const allowDataStep = () => {
    return (materialRequest && Object.keys(materialRequest).length > 0) || (roomRequest && getValues('guests').length > 0);
  }

  /**
   * Validates a guest's id document
   * @param {string} dni: the identity document to validate
   * @returns true if the ID is blank or valid, false otherwise
   */
  const validateGuestIdDocument = (dni) => {
    if (dni.length === 0) {
      return true;
    } // if

    return validateIdentityDocument(dni);
  } // validateGuestIdDocument

  const validationSchema = Yup.object().shape({
    roomNumberOfBeds: Yup.number()
      .required(REQUIRED_ERROR_LABEL)
      .typeError()
      .min(1, f({ id: "form.errors.min" }, { minValue: 1 },)),

    roomCheckInDate: Yup.date().nullable().required(REQUIRED_ERROR_LABEL),

    roomCheckOutDate: Yup.date()
      .nullable()
      .required(REQUIRED_ERROR_LABEL)
      .test(
        'greater',
        'La fecha de salida debe ser posterior a la de entrada',
        function (v) {
          const ref = Yup.ref('roomCheckInDate');
          return moment(v).isAfter(moment(this.resolve(ref)));
        }
      ),

    guests: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string()
          .required(REQUIRED_ERROR_LABEL)
          .min(3, INVALID_MIN_LENGTH_NAME_LABEL)
          .max(50, INVALID_MAX_LENGTH_NAME_LABEL)
          .matches(/^[A-Za-záéíóúÁÉÍÓÚüÜñÑ\s]+$/, INVALID_NAME_LABEL),

        lastName: Yup.string().required(REQUIRED_ERROR_LABEL)
          .min(3, INVALID_MIN_LENGTH_SURNAME_LABEL)
          .max(50, INVALID_MAX_LENGTH_SURNAME_LABEL)
          .matches(/^[A-Za-záéíóúÁÉÍÓÚüÜñÑ\s]+$/, INVALID_SURNAME_LABEL),

        dni: Yup.string()
          .test(
            {
              test: (v) => validateGuestIdDocument(v),
              message: INVALID_ID_NUMBER_LABEL
            }
          ),
        gender: Yup.mixed().nonNullable().required(REQUIRED_ERROR_LABEL),
        position: Yup.mixed().nonNullable().required(REQUIRED_ERROR_LABEL),
      })
    ),
  });

  const { register, handleSubmit, control, formState: { errors }, setValue, getValues, watch } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...roomRequest,
      roomCheckInDate: (roomRequest && roomRequest.roomCheckInDate) ? new Date(roomRequest.roomCheckInDate) : null,
      roomCheckOutDate: (roomRequest && roomRequest.roomCheckOutDate) ? new Date(roomRequest.roomCheckOutDate) : null,
      guests: roomRequest?.guests.map(guest => ({
        ...guest,
        gender: findSelectValue(GENDERS, guest.gender),
        position: findSelectValue(POSITIONS, guest.position),
      }))
    },
    resolver: yupResolver(validationSchema)
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "guests",
  });

  let numberOfBeds = watch('roomNumberOfBeds');
  let guestsLength = watch('guests')?.length;
  let roomCheckInDate = watch('roomCheckInDate');

  const CONTINUE_BUTTON_LABEL = guestsLength === 0 ? "Continuar sin habitaciones" : "Continuar";

  /**
   * Formats the data in the form
   *
   * @param {object} data: the data to be formatted.
   * @returns {object} The formatted data.
   */
  const formatData = (data) => {

    data.roomCheckInDate?.toISOString();
    data.roomCheckOutDate?.toISOString();

    let guests = data.guests;

    guests.forEach(guest => {
      let gender = guest.gender;

      if (gender.value) {
        gender = gender.value;
        guest.gender = gender;
      } // if

      let position = guest.position;

      if (position.value) {
        position = position.value;
        guest.position = position;
      } // if
    }); // forEach

    let dataCopy = { ...data };
    dataCopy.guests = guests;
    data = dataCopy;

    return data;
  } // formatData

  /**
   * Handles the submission of the form data.
   * Dispatches the formatted room request data to the Redux store,
   * adds the ROOMS step to the submitted steps, and proceeds to the next step.
   *
   * @param {object} data: the form data
   */
  const onSubmit = async (data) => {
    dispatch(setRoomRequest(formatData(data)));
    props.addSubmittedStep(requestFormStepsEnum.ROOMS);
    goToNextStep();
  }

  /**
   * Resets the scroll and advances to the next step
   */
  const goToNextStep = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    props.goToNextStep();
  } // goToNextStep

  /**
   * Removes a guest from the index and updates the number of 
   * @param {number} guestIndex: the index of the guest to be removed
   */
  const onRemoveGuestClick = (guestIndex) => {
    remove(guestIndex);
    numberOfBeds--;
    setValue('roomNumberOfBeds', numberOfBeds);
  } // onRemoveGuestClick

  /**
   * Checks if a value is not null, undefined or an empty string
   * @param {object|string} value: the value to be checked on
   * @returns true if the value fits the criteria, false otherwise
   */
  const isNotEmptyValue = (value) => {
    return value !== null && value !== undefined && value !== '';
  } // isNotEmptyValue

  /**
   * Handles a change in the input related to the number of rooms
   * @param {number} newNumberOfBeds: the changed number of beds to change to
   */
  const handleChangeNumberOfRooms = (newNumberOfBeds) => {
    let isModalDisplayable = false;

    if (newNumberOfBeds < fields.length || isNaN(newNumberOfBeds)) {
      isModalDisplayable = getValues('guests').some(guest => {
        return Object.entries(guest).some(([key, value]) => {
          return isNotEmptyValue(value);
        }); // return
      }); // some
    } // if

    if (!isModalDisplayable) {
      setValue('roomNumberOfBeds', newNumberOfBeds);
    } // if

    setShowModal(isModalDisplayable);
  }; // handleChangeNumberOfRooms



  /**
   * Updates the field array
   */
  useEffect(() => {
    let newValue = parseInt(numberOfBeds || 0);
    const previousValue = fields.length;

    // New fields are inserted
    if (newValue > previousValue) {
      // appends to field array
      for (let i = previousValue; i < newValue; i++) {
        append({
          firstName: '',
          lastName: '',
          dni: '',
          gender: null,
          position: null
        });
      } // for
    } else { // Removes as many fields as needed
      for (let i = previousValue; i > newValue; i--) {
        remove(i - 1);
      } // for
    } // if-else
  }, [numberOfBeds]);


  /**
   * Empties the guest data when the number of beds is 0
   */
  useEffect(() => {
    if (numberOfBeds === 0) {
      setValue('guests', []);
    }
  }, [numberOfBeds]);


  // TODO: might need to separate into two different components: 
  // one for the room form and another one for the guests form
  return (
    <div className="row pb-4 mb-5">
      <ConfirmationModal
        showModal={showModal}
        header={f({ id: 'guests.delete.title' })}
        message={f({ id: 'guests.delete.message' })}
        cancelAction={() => setShowModal(false)}
        cancelButtonLabel={f({ id: "app.accept" })}
      />

      {/* ROOM INFO */}
      <div className="col-xs-12 col-lg-8">
        <h2 className="text-color-dark font-weight-bold text-5-5 mb-3">¿Necesitas alojamiento?</h2>
        <p className="mb-4">Contamos con alojamiento habilitado en el CEAR de Vela Príncipe Felipe de Santander. Sujeto a disponibilidad.</p>
        <form onSubmit={handleSubmit(onSubmit)} className="contact-form custom-form-style-1" autoComplete="off">
          <div className="row">
            <div className="form-group col-3 pb-1 mb-3">
              <div className="inner-addon left-addon">
                <i className="icon fa fa-calendar" />
                <Controller
                  control={control}
                  name="roomCheckInDate"
                  render={({ field }) => (
                    <DatePicker
                      locale="es"
                      className="form-control"
                      placeholderText="Fecha de entrada"
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      minDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      showDisabledMonthNavigation
                    />
                  )}
                />
              </div>
              {errors.roomCheckInDate && (
                <FormText color="red" className="text-danger">
                  {errors.roomCheckInDate.message}
                </FormText>
              )}
            </div>
            <div className="form-group col-3 pb-1 mb-3">
              <div className="inner-addon left-addon">
                <i className="icon fa fa-calendar" />
                <Controller
                  control={control}
                  name="roomCheckOutDate"
                  render={({ field }) => (
                    <DatePicker
                      locale="es"
                      className="form-control"
                      placeholderText="Fecha de salida"
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      minDate={addDaysToDate(roomCheckInDate, 1)}
                      dateFormat="dd/MM/yyyy"
                      showDisabledMonthNavigation
                    />
                  )}
                />
              </div>
              {errors.roomCheckOutDate && (
                <FormText color="red" className="text-danger">
                  {errors.roomCheckOutDate.message}
                </FormText>
              )}
            </div>
            <div className="form-group col-3 pb-1 mb-3">
              <input
                name="roomNumberOfBeds"
                id="roomNumberOfBeds"
                value={numberOfBeds}
                onChange={(e) => handleChangeNumberOfRooms(e.target.value)}
                type="number"
                min={0}
                max={39} // 6 rooms x 6 beds each, + a double (2) room, + a single (1) room = 39
                className="form-control"
                placeholder="Nº de camas"
              />
              {errors.roomNumberOfBeds && (
                <FormText color="red" className="text-danger">
                  {errors.roomNumberOfBeds.message}
                </FormText>
              )}
            </div>

          </div>

          {/* GUEST INFO */}
          {
            numberOfBeds > 0 &&
            (
              <>
                <div style={{ marginLeft: "0.25rem" }}>
                  <h4>Información de los huéspedes</h4>
                </div>
                {
                  fields.map((field, index) => (
                    <div className="row" style={{ display: "flex", flexWrap: "nowrap" }} key={field.id}>
                      <div className="form-group col-2 pb-1 mb-3">
                        <input className={`form-control`} placeholder="Nombre" {...register(`guests.${index}.firstName`)} />
                        {errors.guests && errors.guests[index] && errors.guests[index].firstName && (
                          <FormText color="red" className="text-danger">
                            {errors.guests[index].firstName.message}
                          </FormText>
                        )}
                      </div>
                      <div className="form-group col-2 pb-1 mb-3">
                        <input className="form-control" placeholder="Apellidos" {...register(`guests.${index}.lastName`)} />
                        {errors.guests && errors.guests[index] && errors.guests[index].lastName && (
                          <FormText color="red" className="text-danger">
                            {errors.guests[index].lastName.message}
                          </FormText>
                        )}
                      </div>
                      <div className="form-group col-2 pb-1 mb-3">
                        <input className="form-control" placeholder="DNI" {...register(`guests.${index}.dni`)} />
                        {errors.guests && errors.guests[index] && errors.guests[index].dni && (
                          <FormText color="red" className="text-danger">
                            {errors.guests[index].dni.message}
                          </FormText>
                        )}
                      </div>
                      <div className="form-group col-2 pb-1 mb-3 mt-10">

                        <Controller
                          control={control}
                          name={`guests.${index}.gender`}
                          render={({ field }) => {
                            const handleChange = (selectedOption) => {
                              const selectedValue = selectedOption ? selectedOption.value : null;
                              field.onChange(selectedValue);
                            };

                            return (
                              <Select
                                placeholder="Género"
                                isClearable={true}
                                escapeClearsValue={true}
                                options={GENDERS}
                                styles={SELECT_STYLES}
                                isSearchable={false}
                                noOptionsMessage={() => NO_OPTIONS_AVAILABLE_LABEL}
                                defaultValue={getValues()?.guests[index]?.gender}
                                onChange={handleChange}
                              />
                            );
                          }}
                        />

                        {errors.guests && errors.guests[index] && errors.guests[index].gender && (
                          <FormText color="red" className="text-danger">
                            {errors.guests[index].gender.message}
                          </FormText>
                        )}

                      </div>
                      <div className="form-group col-3 pb-1 mb-3">
                        <Controller
                          control={control}
                          name={`guests.${index}.position`}
                          render={({ field }) => {
                            const handleChange = (selectedOption) => {
                              const selectedValue = selectedOption ? selectedOption.value : null;
                              field.onChange(selectedValue);
                            };

                            return (
                              <Select
                                placeholder="Cargo"
                                isClearable={true}
                                escapeClearsValue={true}
                                options={POSITIONS}
                                styles={SELECT_STYLES}
                                isSearchable={false}
                                noOptionsMessage={() => NO_OPTIONS_AVAILABLE_LABEL}
                                defaultValue={getValues()?.guests[index]?.position}
                                onChange={handleChange}
                              />
                            );
                          }}
                        />

                        {errors.guests && errors.guests[index] && errors.guests[index].position && (
                          <FormText color="red" className="text-danger">
                            {errors.guests[index].position.message}
                          </FormText>
                        )}
                      </div>

                      <div style={{ marginTop: '15px' }}>
                        <a
                          role="button"
                          onClick={() => onRemoveGuestClick(index)}
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </div>
                    </div>
                  )) // fields.map
                }

                <div className="row">
                  <div className="form-group col-3 pb-1 mb-3">
                    <button
                      type="submit"
                      className="btn btn-primary btn-modern font-weight-bold text-3 px-4 py-3"
                    >
                      Añadir huésped(es)
                    </button>
                  </div>
                </div>
              </>
            ) // 
          } {/* numberOfBeds > 0 */}

        </form>
        <div className="row pt-4">

          {
            Array.from({ length: 6 }, (_, index) => (
              <div className="col-12 col-sm-6 col-lg-4">
                <img
                  src={`${roomsFilesURL}/room-${index + 1}.jpg`}
                  className="w-100 shadow-1-strong rounded mb-4"
                  alt={`Habitación ${index + 1}`}
                />
              </div>
            ))
          }

        </div>
      </div>
      <div className="col-lg-4 position-relative">
        <div className="pinWrapper">
          <div className="card border-width-3 border-radius-0 border-color-hover-secondary">
            <div className="card-body">
              <Summary
                currentStep={props.currentStep}
                register={register}
                setValue={setValue}
                getFormValues={getValues} />
              {!isLastStep(props.currentStep) &&
                <button
                  type="button"
                  className="btn btn-secondary btn-modern w-100 text-uppercase bg-color-hover-primary border-color-hover-primary border-radius-0 text-3 py-3"
                  onClick={() => {
                    if (allowDataStep()) {
                      props.addSubmittedStep(requestFormStepsEnum.ROOMS);
                      goToNextStep();
                    } else {
                      setShowDataStepMessageError(true);
                    }
                  }}>
                  {CONTINUE_BUTTON_LABEL}
                  <i className="fas fa-arrow-right ms-2" />
                </button>}
              {
                showDataStepMessageError && (
                  <FormText
                    color="red"
                    className="text-danger"
                  >
                    No es posible continuar al siguiente paso: no ha solicitado material ni alojamiento
                  </FormText>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  ); // return
}; // RoomsForm

export default RoomsForm;
