/**
 * Request state
 */
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import * as api from 'api';
import { buildMaterialSummaryDescription } from 'utils/Helpers';

export const initRequest = createAsyncThunk(
  'request/initRequest',
  async (materialTypeCode, thunkAPI) => {
    const materials = await api.material.getByType(materialTypeCode);

    return {
      materials: materials
    };
  }
);


export const getMaterialsByType = createAsyncThunk(
  'request/getMaterialsByType',
  async (materialTypeCode, thunkAPI) => {
    const materials = await api.material.getByType(materialTypeCode);

    return {
      materials: materials
    };
  }
);

export const setDataRequest = createAsyncThunk(
  'request/setDataRequest',
  async (data, thunkAPI) => {
    const { selectedMaterialsAndAmounts, materialRequest, roomRequest } = thunkAPI.getState().request;
    
    // send request
    const response = await api.request.sendRequest({
      materialRequest: selectedMaterialsAndAmounts,
      ...materialRequest,
      ...roomRequest,
      ...data
    });

    return {
      token: response.token,
      locator: response.locator,
      data: data
    };
  }
);

export const sendPin = createAsyncThunk(
  'request/sendPin',
  async (data, thunkAPI) => {
    const { locator } = thunkAPI.getState().request;
    
    // send pin
    const response = await api.request.sendPin({
      locator: locator
    });

    return {
      token: response.token
    };
  }
);

export const confirmRequest = createAsyncThunk(
  'request/confirmRequest',
  async (data, thunkAPI) => {
    const { token } = thunkAPI.getState().request;

    // send request
    // try {
    const isConfirmed = await api.request.confirmRequest({
        pin: data,
        token: token
      });
      
      return { isConfirmed }
    // } catch (e) {
    //   console.log(JSON.stringify(e));
    // }
  }
);

export const getAvailableCities = createAsyncThunk(
  'availableCities',
  async () => {
    const availableCities = await api.request.getAvailableCities();

    return {
      availableCities: availableCities
    };
  }
);


const initialState = {
  initializing: false,
  loading: false,
  materials: [],
  error: null,
  ok: null,
  selectedMaterialsAndAmounts: [],
  materialRequest: {materialReturnDate: null, materialCollectionDate: null},
  roomRequest: undefined,
  dataRequest: undefined,
  token: undefined,
  locator: undefined,
  saving: false,
  confirmed: false,
  sendingSms: false,
  availableCities: []
};


const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    resetOperationOk() {
      return initialState;
    },
    addMaterialAndAmount(state, action) {
      const materialId = action.payload.material.id;
      const materialAlreadyAddedIndex = current(state.selectedMaterialsAndAmounts).map((item) => item.id).indexOf(materialId);
      if (materialAlreadyAddedIndex !== -1) {
        state.selectedMaterialsAndAmounts[materialAlreadyAddedIndex] = {
          "id": materialId,
          "type": current(state.selectedMaterialsAndAmounts[materialAlreadyAddedIndex]).type,
          "amount": current(state.selectedMaterialsAndAmounts[materialAlreadyAddedIndex]).amount + parseInt(action.payload.amount),
          "description": state.selectedMaterialsAndAmounts[materialAlreadyAddedIndex].description
        };
      } else {
        state.selectedMaterialsAndAmounts.push({
          "id": materialId,
          "type": action.payload.material.type,
          "amount": parseInt(action.payload.amount),
          "description": buildMaterialSummaryDescription(action.payload.material)});
      }
    },
    removeMaterial(state, action) {
      const index = current(state.selectedMaterialsAndAmounts).map((item) => item.id).indexOf(action.payload);

      state.selectedMaterialsAndAmounts.splice(index, 1);
    },
    setMaterialRequest(state, action) {
      state.materialRequest = action.payload;
    },
    setRoomRequest(state, action) {
      state.roomRequest = action.payload;
    },
    removeRoomRequest(state, action) {
      state.roomRequest = undefined;
    }
  },
  extraReducers: {
    [initRequest.pending]: (state, action) => {
      state.initializing = true;
      state.materials = [];
    },
    [initRequest.fulfilled]: (state, action) => {
      state.initializing = false;
      state.materials = action.payload.materials;
    },
    [initRequest.rejected]: (state, action) => {
      state.initializing = false;
    },
    [getMaterialsByType.pending]: (state, action) => {
      state.loading = true;
      state.materials = [];
    },
    [getMaterialsByType.fulfilled]: (state, action) => {
      state.loading = false;
      state.materials = action.payload.materials;
    },
    [getMaterialsByType.rejected]: (state, action) => {
      state.loading = false;
    },
    [setDataRequest.pending]: (state, action) => {
      state.saving = true;
    },
    [setDataRequest.fulfilled]: (state, action) => {
      state.saving = false;
      state.token = action.payload.token;
      state.locator = action.payload.locator;
      state.dataRequest = action.payload.data;
    },
    [setDataRequest.rejected]: (state, action) => {
      state.saving = false;
    },
    [sendPin.pending]: (state, action) => {
      state.sendingSms = true;
    },
    [sendPin.fulfilled]: (state, action) => {
      state.sendingSms = false;
      state.token = action.payload.token;
    },
    [sendPin.rejected]: (state, action) => {
      state.sendingSms = false;
    },
    [confirmRequest.pending]: (state, action) => {
      state.saving = true;
      state.ok = false;
    },
    [confirmRequest.fulfilled]: (state, action) => {
      state.saving = false;
      state.confirmed = action.payload.isConfirmed;
      state.ok = true;
    },
    [confirmRequest.rejected]: (state, action) => {
      state.saving = false;
      state.error = action.error.message;
    },
    [getAvailableCities.pending]: (state, action) => {
      state.loading = true;
      state.availableCities = [];
    },
    [getAvailableCities.fulfilled]: (state, action) => {
      state.loading = false;
      state.availableCities = action.payload.availableCities;
    },
    [getAvailableCities.rejected]: (state, action) => {
      state.loading = false;
    },
  }
})

export const {
  addMaterialAndAmount,
  removeMaterial,
  setMaterialRequest,
  setRoomRequest,
  resetOperationOk,
  removeRoomRequest,
} = requestSlice.actions;

export default requestSlice.reducer;
