import { createSlice } from '@reduxjs/toolkit';
import { locales } from 'i18n';


const intlSlice = createSlice({
  name: 'intl',
  initialState: {
    defaultLocale: 'es',
    locale: 'es',
    messages: locales['es']
  },
  reducers: {
    update(state, action) {
      const { locale, messages } = action.payload;
      state.locale = locale;
      state.messages = messages;
    }
  }
});

export const { update } = intlSlice.actions;

export default intlSlice.reducer;