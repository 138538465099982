import React from 'react';
import { format } from 'date-fns';
import { materialTypes, materialTypesEnum, requestFormSteps } from './Enums';


export const formatDate = (date) => {
 // Intl.DateTimeFormat().resolvedOptions().timeZone;
  return format(new Date(date), 'dd/MM/yyyy');
}

/**
 * Dado un valor booleano, retorna 'Sí' o 'No'
 * @param {*} value
 * @returns
 */
export const buildYesOrNo = function(value) {
  return value ? 'Sí' : 'No';
}

/**
 * Devuelve una cadena de texto con la descripción del material en función del tipo
 * @param {*} materialItem
 * @returns
 */
export const buildMaterialSummaryDescription = function(materialItem) {
  let description = materialItem.brand + ' ' + materialItem.model;

  switch(materialItem.materialType) {
    case materialTypesEnum.BOAT:
      description += ' - Potencia: ' + materialItem.power;
    break;

    case materialTypesEnum.TRAILER:
      description += ' - Características: ' + materialItem.features;
    break;

    case materialTypesEnum.VAN:
      description += ' - ' + materialItem.numberOfSeats + ' plazas';

      if (materialItem.adapted) {
        description += ' - Adaptada';
      }

      if (materialItem.hasRoofRack) {
        description += ' - Baca';
      }

      if (materialItem.hasCouplingBall) {
        description += ' - Bola remolque';
      }
    break;
  }

  return description;
}

/**
 * Dado el código de un tipo de material, obtiene su descripción
 * @param {*} materialTypeCode
 * @returns
 */
export const getMaterialTypeDescription = function(materialTypeCode) {
  const materialType = materialTypes.find((item) => item.code === materialTypeCode);

  return materialType.description;
}

/**
 * Obtiene el siguiente paso del formulario de reserva
 * @param {*} currentStep
 * @returns
 */
 export const getNextStep = function(currentStep) {
  const currentStepIndex = requestFormSteps.map((item) => item.code ).indexOf(currentStep);
  return requestFormSteps[currentStepIndex + 1].code;
}

/**
 * Obtiene el paso anterior del formulario de reserva
 * @param {*} currentStep
 * @returns
 */
export const getPreviousStep = function(currentStep) {
  const currentStepIndex = requestFormSteps.map((item) => item.code ).indexOf(currentStep);
  return requestFormSteps[currentStepIndex - 1].code;
}

/**
 * Comprueba si el step actual es el primero
 * @param {*} currentStep
 * @returns
 */
export const isFirstStep = function(currentStep) {
  const currentStepIndex = requestFormSteps.map((item) => item.code ).indexOf(currentStep);
  return currentStepIndex === 0;
}

/**
 * Obtiene el paso actual es el último
 * @param {*} materialTypeCode
 * @returns
 */
 export const isLastStep = function(currentStep) {
  const currentStepIndex = requestFormSteps.map((item) => item.code ).indexOf(currentStep);
  return currentStepIndex === requestFormSteps.length -1 ;
}

/**
 * Comprueba si el paso siguiente es anterior al actual
 * @param {*} currentStep 
 * @param {*} newStep 
 */
export const isPreviousStep = function(currentStep, newStep) {
  const currentStepIndex = requestFormSteps.map((item) => item.code ).indexOf(currentStep);
  const newStepIndex = requestFormSteps.map((item) => item.code ).indexOf(newStep);

  return newStepIndex < currentStepIndex;
}

/**
 * Comprueba si el paso siguiente es anterior al actual
 * @param {*} currentStep 
 * @param {*} newStep 
 */
export const getLastStep = function() {
  return requestFormSteps[requestFormSteps.length - 1];
}



  /**
   * Finds the label-value element in an array based on the given value
   * @param {object} selectValues: the list of label-value elements
   * @param {string|object} value: the value to find or an object with a 'value' property 
   * @returns {object|string} the object if the passed paremeter is a label-value object, 
   *                          the label-value if the passed value is found in the array,
   *                          null otherwise
   */
  export const findSelectValue = (selectValues, value) => {

    let option;
    
    if (value?.value) {
      // The element is already a label-value element
      option = value;
    } else {
      // The element is a string, it finds the vlaue
      option = selectValues.find(item => item.value === value);
    } // if-else

    return option;
  }



  /**
   * Adds a number of days to a date
   * @param {Date|string} date: the date to which the days are to be added
   * @param {number} days: the days to add
   * @returns the date with the added days
   */
  export const addDaysToDate = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  } // addDaysToDate