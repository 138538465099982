import React from 'react';

import { requestFormStepsEnum } from 'utils/Enums';

import MaterialForm from './MaterialForm';
import RoomsForm from './RoomsForm';
import DataForm from './DataForm';
import Confirmation from './Confirmation';

const RequestForm = (props) => {

  return  <div className="row pb-4 mb-5 pl-3 pr-3">
    {requestFormStepsEnum.MATERIAL === props.currentStep &&
      <MaterialForm
        currentStep={props.currentStep}
        goToPreviousStep={props.goToPreviousStep}
        goToNextStep={props.goToNextStep}
        setCurrentStep={props.setCurrentStep}

        addSubmittedStep={props.addSubmittedStep}
        />
    }

    {requestFormStepsEnum.ROOMS === props.currentStep &&
      <RoomsForm
        currentStep={props.currentStep}
        goToPreviousStep={props.goToPreviousStep}
        goToNextStep={props.goToNextStep}
        setCurrentStep={props.setCurrentStep}
        addSubmittedStep={props.addSubmittedStep}
      />
    }

    {requestFormStepsEnum.DATA === props.currentStep &&
      <DataForm
        currentStep={props.currentStep}
        goToPreviousStep={props.goToPreviousStep}
        goToNextStep={props.goToNextStep}
        addSubmittedStep={props.addSubmittedStep}
      />
    }
    {requestFormStepsEnum.CONFIRMATION === props.currentStep &&
      <Confirmation
        currentStep={props.currentStep}
        goToPreviousStep={props.goToPreviousStep}
        goToNextStep={props.goToNextStep}
      />
    }
    </div>
};

export default RequestForm;
