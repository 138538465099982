/**
 * Class to Validate Identity Documents
 */

export const validateIdentityDocument = (nifToValidate) => {
	const DNI_REGEX = /^(\d{8})([A-Z])$/;
	const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
	const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

	let document = nifToValidate;
	let documentIsValid = false;

	if (document) {
		document = document.toUpperCase().replace(/\s/, '');

		// get document type
		let documentType;
		if (document.match(DNI_REGEX)) {
			documentType = 'dni';
		} else if (document.match(CIF_REGEX)) {
			documentType = 'cif';
		} else if (document.match(NIE_REGEX)) {
			documentType = 'nie';
		} // if-else if

		switch (documentType) {
			case 'dni':
				documentIsValid = validateDni(document);
				break;
			case 'nie':
				documentIsValid = validateNie(document);
				break;
			case 'cif':
				documentIsValid = validateCif(document, CIF_REGEX);
				break;
			default:
				documentIsValid = false;
				break;
		}
	}

	return documentIsValid;
};

const validateDni = function (document) {
	const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
	let letter = dni_letters.charAt(parseInt(document, 10) % 23);

	return letter === document.charAt(8);
};

const validateNie = function (document) {
	// Change the initial letter for the corresponding number and validate as DNI
	let niePrefix = document.charAt(0);

	switch (niePrefix) {
		case 'X': niePrefix = 0; break;
		case 'Y': niePrefix = 1; break;
		case 'Z': niePrefix = 2; break;
		default: break;
	}
	return validateDni(niePrefix + document.substr(1));
};

const validateCif = function (document, cifRegex) {
	var match = document.match(cifRegex);
	let letter = match[1],
		number = match[2],
		control = match[3];

	let evenSum = 0;
	let oddSum = 0;
	let n;

	for (var i = 0; i < number.length; i++) {
		n = parseInt(number[i], 10);

		// Odd positions (Even index equals to odd position. i=0 equals first position)
		if (i % 2 === 0) {
			// Odd positions are multiplied first.
			n *= 2;

			// If the multiplication is bigger than 10 we need to adjust
			oddSum += n < 10 ? n : n - 9;

		// Even positions
		// Just sum them
		} else {
			evenSum += n;
		}
	}

	let controlDigit = (10 - (evenSum + oddSum).toString().slice(-1)).toString().slice(-1);
	let controlLetter = 'JABCDEFGHI'.slice(controlDigit, 1);

	// Control must be a digit
	if (letter.match(/[ABEH]/)) {
		return control === controlDigit;

		// Control must be a letter
	} else if (letter.match(/[KPQS]/)) {
		return control === controlLetter;

		// Can be either
	} else {
		return control === controlDigit || control === controlLetter;
	}
};
