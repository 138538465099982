import { yupResolver } from '@hookform/resolvers/yup';
import es from 'date-fns/locale/es';
import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { FormText } from "reactstrap";
import * as Yup from "yup";

import {
  setMaterialRequest
} from "state/request";

import { addDaysToDate, findSelectValue, isLastStep } from 'utils/Helpers';

import { useIntl } from 'react-intl';
import { SELECT_STYLES } from 'utils/constants';
import { materialTypesEnum, requestFormStepsEnum } from 'utils/Enums';
import MaterialSelector from './MaterialSelector';
import Summary from './Summary';

const MaterialForm = (props) => {
  registerLocale('es', es);
  const dispatch = useDispatch();
  let materialRequest = useSelector(state => state.request.materialRequest);
  const selectedMaterials = useSelector(state => state.request.selectedMaterialsAndAmounts);
  const availableCities = useSelector(state => state.request.availableCities);

  const { formatMessage: f } = useIntl();
  const REQUIRED_ERROR_LABEL = f({ id: "form.errors.required" });
  const END_DATE_GREATER_THAN_START_DATE_ERROR_LABEL = f({ id: "form.errors.date" });
  const CONTINUE_LABEL = selectedMaterials.length === 0 ? "CONTINUAR SIN MATERIAL" : "CONTINUAR"

  const isMaterialRequired = (selectedMaterials, value) => {
    return selectedMaterials.length > 0 && value || selectedMaterials.length === 0;
  };


  const validationSchema = Yup.object({
    materialEventName: Yup.string()
      .ensure()
      .test('required', REQUIRED_ERROR_LABEL, (v) => isMaterialRequired(selectedMaterials, v)),

    materialCollectionDate: Yup.date()
      .nullable()
      .test('required', REQUIRED_ERROR_LABEL, (v) => isMaterialRequired(selectedMaterials, v))
      .typeError(REQUIRED_ERROR_LABEL),

    materialCollectionPlace: Yup.mixed()
      .nullable()
      .test('required', REQUIRED_ERROR_LABEL, (v) => isMaterialRequired(selectedMaterials, v)),

    materialReturnDate: Yup.date()
      .nullable()
      .test('required', REQUIRED_ERROR_LABEL, (v) => isMaterialRequired(selectedMaterials, v))
      .typeError(f({ id: "form.errors.required" }))
      .test('greater', END_DATE_GREATER_THAN_START_DATE_ERROR_LABEL, function (value) {
        const { materialCollectionDate } = this.parent;
        return materialCollectionDate ? materialCollectionDate <= value : true;
      }),

    materialReturnPlace: Yup.mixed()
      .nullable()
      .test('required', REQUIRED_ERROR_LABEL, (v) => isMaterialRequired(selectedMaterials, v))
  }).required();


  const { register, handleSubmit, control, formState: { errors }, setValue, getValues, watch } = useForm({
    defaultValues: {
      ...materialRequest,
      materialCollectionDate: (materialRequest && materialRequest.materialCollectionDate) ? new Date(materialRequest.materialCollectionDate) : null,
      materialReturnDate: (materialRequest && materialRequest.materialReturnDate) ? new Date(materialRequest.materialReturnDate) : null,

      materialCollectionPlace: findSelectValue(availableCities, materialRequest?.materialCollectionPlace),
      materialReturnPlace: findSelectValue(availableCities, materialRequest?.materialReturnPlace),
    },
    resolver: yupResolver(validationSchema)
  });

  let materialCollectionDate = watch('materialCollectionDate');


  const formatData = (data) => {
    data.materialEventName = data.materialEventName.trim();
    if (data.materialCollectionDate) {
      data.materialCollectionDate = data.materialCollectionDate.toISOString();
    }

    if (data.materialReturnDate) {
      data.materialReturnDate = data.materialReturnDate.toISOString();
    }

    // Fixes a bug which sets the label-value object when going a step back and setting a new value
    if (data.materialCollectionPlace.value) {
      data.materialCollectionPlace = data.materialCollectionPlace.value;
    }

    // Idem
    if (data.materialReturnPlace.value) {
      data.materialReturnPlace = data.materialReturnPlace.value;
    } // if

    return data;
  }

  const isFormCompleted = (formData) => { // TODO ¿¿¿necesario???
    let allFieldsCompleted = true;

    const keys = Object.keys(formData);

    keys?.forEach((item) => {
      if (!formData[item]) {
        allFieldsCompleted = false;
      } // if
    }); // forEach

    return allFieldsCompleted;
  } // isFormCompleted

  const onSubmit = (data) => {
    // se setean los datos sólo si se han cumplimentado
    if (selectedMaterials.length > 0 && isFormCompleted(data)) {
      const clonedData = data;
      dispatch(setMaterialRequest(formatData(clonedData)));
    } else {
      dispatch(setMaterialRequest(undefined));
    } // if-else

    skipStep();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const skipStep = () => {
    props.addSubmittedStep(requestFormStepsEnum.MATERIAL);
    props.goToNextStep();
  }

  return <React.Fragment>
    <MaterialSelector />
    <div className="col-xs-12 col-lg-4">
      <div className="pinWrapper">
        <div className="card border-width-3 border-radius-0 border-color-hover-secondary">
          <div className="card-body">
            <h4 className="font-weight-bold text-uppercase text-4 mb-3">Datos de la reserva</h4>
            <form id={materialTypesEnum.BOAT} onSubmit={handleSubmit(onSubmit)} className="custom-form-style-1" autoComplete="off">
              <div className="row">
                <div className="form-group col pb-1 mb-3">
                  <input
                    {...register('materialEventName')}
                    name="materialEventName"
                    id="materialEventName"
                    type="text"
                    placeholder="Concentración/Competición"
                    className="form-control"
                  />
                  {errors.materialEventName && (
                    <FormText color="red" className="text-danger">
                      {errors.materialEventName.message}
                    </FormText>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-group col pb-1 mb-3">
                  <div className="inner-addon left-addon">
                    <i className="icon fa fa-calendar" />

                    <Controller
                      control={control}
                      name="materialCollectionDate"
                      render={({ field }) => (
                        <DatePicker
                          locale={es}
                          className="form-control"
                          placeholderText="Fecha de recogida"
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          showDisabledMonthNavigation={false}
                        />
                      )}
                    />
                  </div>

                  {
                    errors.materialCollectionDate && (
                      <FormText color="red" className="text-danger">
                        {errors.materialCollectionDate.message}
                      </FormText>
                    )
                  }
                </div>
              </div>

              <div className="row">
                <div className="form-group col pb-1 mb-3">
                  <Controller
                    control={control}
                    name="materialCollectionPlace"
                    render={({ field }) => {
                      const handleChange = (selectedOption) => {
                        const selectedValue = selectedOption ? selectedOption.value : null;
                        field.onChange(selectedValue);
                      };
                      return (
                        <Select
                          placeholder="Ciudad de recogida"
                          isClearable={true}
                          escapeClearsValue={true}
                          options={availableCities}
                          styles={SELECT_STYLES}
                          isSearchable={false}
                          noOptionsMessage={() => 'No hay opciones disponibles'}
                          onChange={handleChange}
                          defaultValue={findSelectValue(availableCities, materialRequest?.materialCollectionPlace)}
                        />
                      );
                    }}
                  />
                  <FormText color="red" className="text-danger">
                    {errors.materialCollectionPlace?.message}
                  </FormText>
                </div>
              </div>
              <div className="row">
                <div className="form-group col pb-1 mb-3">
                  <div className="inner-addon left-addon">
                    <i className="icon fa fa-calendar" />
                    <Controller
                      control={control}
                      name="materialReturnDate"
                      render={({ field }) => (
                        <DatePicker
                          locale="es"
                          className="form-control"
                          placeholderText="Fecha de entrega"
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          minDate={addDaysToDate(materialCollectionDate, 1)} dateFormat="dd/MM/yyyy"
                          showDisabledMonthNavigation={false}
                        />
                      )}
                    />
                  </div>
                  {errors.materialReturnDate && (
                    <FormText color="red" className="text-danger">
                      {errors.materialReturnDate.message}
                    </FormText>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-group col pb-1 mb-3">
                  <Controller
                    control={control}
                    name="materialReturnPlace"
                    render={({ field }) => {
                      const handleChange = (selectedOption) => {
                        const selectedValue = selectedOption ? selectedOption.value : null;
                        field.onChange(selectedValue);
                      };

                      return (
                        <Select
                          placeholder="Ciudad de entrega"
                          isClearable={true}
                          escapeClearsValue={true}
                          options={availableCities}
                          styles={SELECT_STYLES}
                          isSearchable={false}
                          noOptionsMessage={() => 'No hay opciones disponibles'}
                          onChange={handleChange}
                          defaultValue={findSelectValue(availableCities, getValues()?.materialReturnPlace)}
                        />
                      );
                    }}
                  />
                </div>
                <FormText color="red" className="text-danger">
                  {errors.materialReturnPlace?.message}
                </FormText>
              </div>
              <Summary
                currentStep={props.currentStep}
                register={register}
                setValue={setValue}
                getFormValues={getValues}
                view="material"
              />
              {
                !isLastStep(props.currentStep) &&
                (

                  <button
                    type="submit"
                    className="btn btn-secondary btn-modern w-100 text-uppercase bg-color-hover-primary border-color-hover-primary border-radius-0 text-3 py-3">
                    {CONTINUE_LABEL}
                    <i className="fas fa-arrow-right ms-2" />
                  </button>
                )
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
};

export default MaterialForm;
